import Description from 'pages/App/Billing/Invoices/InvoicesTable/Description/Description.tsx'
import Cell from 'pages/App/Billing/Invoices/InvoicesTable/Cell/Cell.tsx'
import { CellVariant } from 'pages/App/Billing/Invoices/InvoicesTable/Cell/types.ts'
import dayjs from 'dayjs'
import { formatAsCurrency } from 'utils/format.ts'
import Status from 'pages/App/Billing/Invoices/InvoicesTable/Status/Status.tsx'
import AccountingInfo from 'pages/App/Billing/Invoices/InvoicesTable/AccountingInfo/AccountingInfo.tsx'
import Options from 'pages/App/Billing/Invoices/InvoicesTable/Options/Options.tsx'
import PaymentButton from 'pages/App/Billing/Invoices/InvoicesTable/PaymentButton/PaymentButton.tsx'
import InvoiceDownload from 'pages/App/Billing/Invoices/InvoicesTable/InvoiceDownload/InvoiceDownload.tsx'
import { AccountingRowsProps, TransactionRowsProps } from 'pages/App/Billing/Invoices/hooks/types.ts'
import i18next from 'i18next'
import { Row } from 'components/Table/types.ts'
import { CustomerInvoice, DocumentType } from 'types/types.ts'
import { InvoiceTypeTranslationKeys } from 'pages/App/Billing/Invoices/constants.ts'
import resources from 'translations/dutch'
import { getStatus } from 'pages/App/Billing/Invoices/InvoicesTable/Status/utils.ts'
import { CellType } from 'pages/App/Billing/Invoices/InvoicesTable/types.ts'
import { formatAddress } from '../../../../../utils/user.ts'
import { getContractByContractNumber } from '../../../../../utils/contracts.ts'

/**
 * Returns the invoices table rows
 *
 * @param {AccountingRowsProps} props
 * @returns {Row[]}
 */
export const getInvoiceRows = ({
  documents,
  isSmaller,
  isSuperuser,
  isDomiciled,
  language,
  customers,
  selectedCustomer
}: AccountingRowsProps): Row[] => {
  return documents.map((document) => ({
    cells: [
      {
        value: <Description document={document as CustomerInvoice} />,
        rawData: {
          [CellType.DESCRIPTION]: `${
            document?.documentType === DocumentType.INVOICE
              ? i18next.t(
                  `invoiceTypes.${InvoiceTypeTranslationKeys[document.invoiceType] as unknown as keyof typeof resources.billing.invoiceTypes}`,
                  '',
                  { ns: 'billing' }
                )
              : i18next.t('invoices:cells.bankTransaction')
          }`,
          [CellType.ADDRESS]:
            document.documentType !== DocumentType.PAYMENT
              ? formatAddress(
                  getContractByContractNumber(customers[selectedCustomer]?.contracts, document.electricityContractNumber)?.address
                )
              : ''
        },
        id: CellType.ADDRESS
      },
      ...(!!isSuperuser || !isSmaller
        ? [
            {
              value: <Cell variant={CellVariant.DATE}>{dayjs(document.date).format('DD/MM/YYYY')}</Cell>,
              rawData: document.date,
              id: CellType.DATE
            }
          ]
        : []),
      {
        value: (
          <Cell variant={CellVariant.PAYMENT_AMOUNT}>
            {formatAsCurrency({
              num: document.amount,
              lang: language
            })}
          </Cell>
        ),
        rawData: document.amount,
        id: CellType.AMOUNT
      },
      ...(isSuperuser
        ? [
            {
              value: (
                <Cell variant={CellVariant.RUNNING_BALANCE}>
                  {formatAsCurrency({
                    num: document.runningBalance,
                    lang: language
                  })}
                </Cell>
              ),
              rawData: document.runningBalance,
              id: CellType.RUNNING_BALANCE
            }
          ]
        : []),
      {
        value: <Status document={document} isDomiciled={isDomiciled} />,
        rawData: getStatus({ document, isDomiciled }).value,
        id: CellType.STATUS
      },
      ...(isSuperuser
        ? [
            { value: <AccountingInfo document={document} />, rawData: document.matchingNumber, id: 'matchingNumber' },
            {
              value: (
                <Cell variant={CellVariant.PAYMENT_METHOD}>
                  {document.directDebitSent && <span title={i18next.t('invoices:cells.directDebitSent')}>D</span>}
                  {document.paymentSent && <span title={i18next.t('invoices:cells.paymentSent')}>B</span>}
                </Cell>
              )
            },
            {
              value: <Options document={document} />
            }
          ]
        : [
            ...(!isDomiciled
              ? [
                  {
                    value: <PaymentButton document={document} />
                  }
                ]
              : []),
            {
              value: <InvoiceDownload document={document} />
            }
          ])
    ]
  })) as Row[]
}

/**
 * Returns the transaction table rows
 *
 * @param {AccountingRowsProps} props
 * @returns {Row[]}
 */
export const getTransactionRows = ({ documents, isDomiciled, language }: TransactionRowsProps): Row[] => {
  return documents.map((document) => ({
    cells: [
      {
        value: (
          <Cell variant={CellVariant.DESCRIPTION}>
            <span>{i18next.t('invoices:cells.bankTransaction')}</span>
          </Cell>
        )
      },
      { value: <Cell variant={CellVariant.DATE}>{dayjs(document.date).format('DD/MM/YYYY')}</Cell> },
      {
        value: (
          <Cell variant={CellVariant.PAYMENT_AMOUNT}>
            {formatAsCurrency({
              num: document?.amount,
              lang: language
            })}
          </Cell>
        )
      },
      { value: <Status document={document} isDomiciled={isDomiciled} /> }
    ]
  }))
}
