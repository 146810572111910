import { InvoiceType } from 'types/types.ts'
import i18n from 'translations/i18next.ts'
import { CellType, TableHeadersType } from 'pages/App/Billing/Invoices/InvoicesTable/types.ts'

// Update scss variable $invoicesTableBreakpoint as well!
export const invoicesTableBreakpoint = 1170

export const tableHeaders = () =>
  [
    {
      value: i18n.t('tableHeaders.description', { ns: 'invoices' })
    },
    {
      value: i18n.t('tableHeaders.date', { ns: 'invoices' })
    },
    {
      value: i18n.t('tableHeaders.amount', { ns: 'invoices' })
    },
    {
      value: i18n.t('tableHeaders.status', { ns: 'invoices' })
    },
    {
      value: ''
    },
    {
      value: ''
    }
  ] as TableHeadersType

export const superUserTableHeaders = () =>
  [
    {
      values: [
        {
          value: i18n.t('tableHeaders.description', { ns: 'invoices' }),
          id: CellType.DESCRIPTION
        },
        {
          value: i18n.t('tableHeaders.address', { ns: 'invoices' }),
          id: CellType.ADDRESS
        }
      ]
    },
    {
      value: i18n.t('tableHeaders.date', { ns: 'invoices' }),
      id: CellType.DATE
    },
    {
      value: i18n.t('tableHeaders.amount', { ns: 'invoices' }),
      id: CellType.AMOUNT
    },
    {
      value: i18n.t('tableHeaders.outstanding', { ns: 'invoices' }),
      id: CellType.RUNNING_BALANCE
    },
    {
      value: i18n.t('tableHeaders.status', { ns: 'invoices' }),
      id: CellType.STATUS
    },
    {
      value: i18n.t('tableHeaders.accountingInfo', { ns: 'invoices' }),
      id: CellType.MATCHING_NUMBER
    },
    {
      value: ''
    },
    {
      value: ''
    }
  ] as TableHeadersType

export const InvoiceTypeTranslationKeys: { [key in InvoiceType]: string } = {
  [InvoiceType.INSTALMENT]: 'deposit',
  [InvoiceType.INSTALMENT_CREDITNOTE]: 'deposit',
  [InvoiceType.SETTLEMENT]: 'settlement',
  [InvoiceType.SETTLEMENT_CREDITNOTE]: 'settlement',
  [InvoiceType.FINAL_SETTLEMENT]: 'finalSettlement',
  [InvoiceType.FINAL_SETTLEMENT_CREDITNOTE]: 'finalSettlement',
  [InvoiceType.INJECTION_SETTLEMENT_PRO]: 'injectionSettlementPro',
  [InvoiceType.INJECTION_SETTLEMENT_PRO_CREDITNOTE]: 'injectionSettlementPro',
  [InvoiceType.INJECTION]: 'injection',
  [InvoiceType.INJECTION_CREDITNOTE]: 'injection',
  [InvoiceType.BUDGET_METER]: 'budgetMeter',
  [InvoiceType.BUDGET_METER_CREDITNOTE]: 'budgetMeter',
  [InvoiceType.RECTIFICATION]: 'rectification',
  [InvoiceType.RECTIFICATION_CREDITNOTE]: 'rectification',
  [InvoiceType.CANCEL]: 'annulation',
  [InvoiceType.CANCEL_CREDITNOTE]: 'annulation',
  [InvoiceType.DIVERSE]: 'additionalInvoice',
  [InvoiceType.DIVERSE_CREDITNOTE]: 'additionalCompensation',
  [InvoiceType.MANUAL_CREDITNOTE]: 'friendsDiscount',
  [InvoiceType.CASHBACK]: 'cashback',
  [InvoiceType.PURCHASE_INJECTION]: 'injection',
  [InvoiceType.PURCHASE_INJECTION_CREDITNOTE]: 'injection',
  [InvoiceType.PURCHASE_INJECTION_PRO]: 'injection',
  [InvoiceType.PURCHASE_INJECTION_PRO_CREDITNOTE]: 'injection',
  [InvoiceType.MISC_INVOICE]: 'miscellaneousInvoice',
  [InvoiceType.MISC_CREDITNOTE]: 'miscellaneousCreditnote'
}
